/* ==========================================================================
	Event / Blog Detail
========================================================================== */

.backtoList{
	a{
		color:$gray;
		display: block;
		padding-bottom:10px;

		&:hover{
			color:$purple;
		}
	}
}

.blogeventName{
	margin-bottom:8px;
}

.eventTop{

	.time{
		@include breakpoint($small){
			float:right;
		}
	}
}

.blogeventDetails{
	background:$tan;
	padding:10px 20px;
	margin: 0 auto rhythm(1);
	position: relative;
	@include clearfix;

	p{
		margin:0;

		@include breakpoint($small){
			display: inline-block;
			vertical-align:top;
		}
	}

	.postDate{
		@include breakpoint($small){
			float:right;
		}
  }

  .date-repeat-rule {
    @extend .visuallyhidden;
  }
  
  .date-display-single {
    display: block;
  }

  .date-display-range {
    .date-display-start {
      margin-right: .5em;
    }
    .date-display-end {
      margin-left: .5em;
    }
  }
}

.category{
	color:$gray;
	font-weight:700;
	@include adjust-font-size-to(14px);

	a{
		color:$gray;
		font-weight:400;

		&:hover{
			color:$purple;
		}
	}
}

.view-events {
    label {
        @extend .visuallyhidden;
    }
}