/* ==========================================================================
	Navigation
========================================================================== */

.siteNavigation {
	position: fixed;
	opacity:0;
	width:100%;
	height:100%;
	background:rgba(#000, 0.92);
	transform: translate(0, -170%);
	overflow-y:scroll;
	padding-right:100px;

	@include breakpoint($large){
		position: relative;
		background:rgba($cream, 0.9);
		overflow:auto;
		padding: 10px 0;
		opacity:1;
		transform:translate(0,0);
		margin-top:-5px;
	}
	h4 {
		color: #000;
	}
	> ul{
		padding:0 20px;
		list-style-type: none;
		text-align:center;
		font-family:$sans-serif;
		letter-spacing:1px;
		@include adjust-font-size-to(32px);
		margin-top:15%;

		@include breakpoint($large){
			margin:0 auto;
			padding:0;
			@include adjust-font-size-to(20px);
			letter-spacing:0;
		}

		li{
			margin:15px 0;
			position: relative;

			@include breakpoint($large){
				display: inline-block;
				vertical-align:top;
				margin: 9px 0;
				padding:0 20px
			}

			&:nth-child(2){
				@include breakpoint($large){
					margin-right:200px;
				}
			}
		}

		a{
			color:#fff;
			font-weight:700;
			display: block;
			padding: 8px 0;
			text-transform:uppercase;
			text-decoration: none;

			@include breakpoint($large){
				color:#000;
				font-weight:500;
				padding:8px 10px;
			}

			&:hover{
				color:$purple;
			}

			&.hasChildren{
				padding:0 35px;

				@include breakpoint($large){
					padding:8px 0;
				}
			}

			&.plus{
				padding:0;
				position: absolute;
				top:3px; right:0px;
				width: 30px;
				height:30px;
				border:0;
				text-indent: -9999px;
				transition: all 0.5s ease;
				z-index: 10;
				background:url('../img/icon-plus-white.png') no-repeat center;
				background-image: url('../img/icon-plus-white.svg'),none;
				background-size:20px;
				transition:all 0.5s ease;

				@include breakpoint($large){
					display: none !important;
				}
			}

			&.plusOpen{
				transform:rotate(135deg);
				transition:(all 0.5s ease);

			}
		}

		ul{
			display: none;
			@include adjust-font-size-to(12px);
			list-style-type: none;
			padding:0;

			li{
				padding:0;
			}

			a{
				padding:0;
			}
		}
	}
}

.navOpen{
	.siteNavigation{
		opacity:1;
		transform:translate(0,0);
		transition:(opacity 0.5s ease);
		top:0; left:0;
		z-index: 100;
		padding:0;
	}
}