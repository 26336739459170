/* ==========================================================================
	Mixins
========================================================================== */

// px to rem
@function rem($px) {
  @return ($px/16px)+rem;
}

// Media Queries
//   Usage:
//   @include breakpoint($large) { };
//   @include breakpoint(400px, min-height) { };
$media-feature: 'min-width' !default;
@mixin breakpoint($point, $feature: $media-feature) {
    @media (#{$feature}: $point) {
        @content;
    }
}
@mixin bp($point, $feature: $media-feature) {
    @media (#{$feature}: $point) {
        @content;
    }
}

// Contain Floats
@mixin clearfix {
	&:after { content: ""; display: table; clear: both; }
}

// Cross-browser opacity
@mixin opacity($trans) {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$trans * 100})";
    filter: alpha(opacity=#{$trans * 100});
    opacity: $trans;
}

// Placeholder styling
@mixin placeholder {
    $placeholders: ":-webkit-input" ":-moz" "-moz" "-ms-input";
    @each $placeholder in $placeholders {
        &:#{$placeholder}-placeholder {
            @content;
        }
    }
    &.placeholder {
        @content;
    }
}

// Font Smoothing - Mac OS X only
@mixin font-smoothing($value: on) {
    @if $value == on {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

// SVG background image with PNG fallback
//   Usage:
//   @include background-image(logo);
//   @include background-image(logo, 180px 60px);
$image-path: '../img' !default;
$fallback-extension: 'png' !default;
@mixin background-image($name, $size:false){
    background: url(#{$image-path}/#{$name}.#{$fallback-extension}) no-repeat 0 0;
    background-image: linear-gradient(transparent,transparent),url(#{$image-path}/#{$name}.svg);
    @if($size){
        background-size: $size;
    }
}
