/*====================================================
=            ON TAP MENU  (/on-tap-menu)             =
====================================================*/


.onTapColumns {
    max-width: 960px; //845px;

    .onTapMenu-printInstructions {
        background: $tan;
        padding: 1em 2em;
        margin: 1em 0;

        h1 {
            margin: 0 auto .25em;
            color: $purple;
        }

        ul {
            @include adjust-font-size-to(18px);
            list-style-position: inside;
            padding: 0;
            margin: 0;

            li {
                margin: 0 auto .5em;
            }
        }
    }

    .onTapMenu-heading {
        text-align:center;
        color:$purple;
        text-transform:uppercase;
        //width: 50%;
        
        display: block;
        position: relative;
        background:url('../img/labelDots.png') repeat-x left center;
        background-size:3px 12px;
        margin-bottom:5px;
        -webkit-print-color-adjust: exact;

        .section-title {
            font-family:$sans-serif;
            font-size: 1em;
            margin:0;
            background:$cream;
            padding:0 5px;
            display: inline-block;
            line-height:16px;
            font-weight: 400;
        }
    }

    .onTapMenu-list {
        
        ul {
            // height: 1500px;
            
            
            list-style: none;
            padding: 0;
            margin: 0 auto 1em;
            width: 100%;

            columns: 350px 2;
            column-gap: 2em;
            -webkit-perspective:1;
            perspective: 1;

            &.containers {
                li {
                    .selection-title {
                        span {
                            & + span {
                                @include adjust-font-size-to(12px);
                                font-family: $sans-serif;
                                font-weight: 200;
                            }
                        }
                    }
                }
            }

            li,
            .selection {
                //width: 50%;
                padding: 0;
                margin: 0 auto 1em;
                display: inline-block;
                width: 100%;

                .selection-title {
                    @include clearfix;

                    font-size: 1em;
                    margin: 0;
                    font-weight: 200;
                    position: relative;

                    //border-bottom: 1px dotted #575757; 

                    // background-image: linear-gradient(to right, black 33%, rgba(255,255,255,0) 0%);
                    // background-position: bottom;
                    // background-size: 3px 1px;
                    // background-repeat: repeat-x;

                    &:before {
                        content: '';
                        width: 100%;
                        position: absolute;
                        height: 1px;
                        border: 1px dotted #575757;
                        border-bottom: 0;
                        top: 1em;
                        display: block;
                    }

                    span {
                        font-weight: 400;
                        float: left;
                        background: $cream;
                        position: relative;
                        z-index: 1;
                        padding: 0 5px 0 0;
                        font-family: $sans-serif;

                        // font-family: $sans-serif;
                        // font-weight: 200;
                        // font-size: 14px;

                        & + span {
                            //font-weight: 400;
                            float: right;
                            padding: 0 0 0 5px;
                            // font-size: 16px;
                        }
                    }
                }

                .selection-info {
                    @include clearfix;
                    // @include adjust-font-size-to(12px);
                    // line-height:12px;
                    line-height: 1;
                    font-family: $sans-serif;
                    @include adjust-font-size-to(12px);
                    font-weight: 200;

                    span {
                        float: left;
                        font-style: italic;

                        &.prices {
                            float: right;
                            font-style: normal;
                        }
                    }
                }
            } // end li, .selection
        }
    }
}





.node-type-on-tap-menu {
    
    @media print {
        @page { 
            size: 8.5in 11in; 
            margin: 2cm;
        }
        * {
            -webkit-print-color-adjust: exact !important;
            print-color-adjust: exact !important;
        }

        html body {
            margin-top: 0 !important; 
        }

        .tabs,
        .onTapMenu-printInstructions {
            display: none;
        }

        .container {
            .wrapper {
                padding: 0 !important;
                margin: 0;
            }
        }

        .onTapColumns {
            padding: 0;

            h1.title {
                text-align: center;
                margin: 0 auto 1em;
                line-height: 1;
                font-size: 20pt;
            }
        }

        .onTapMenu-heading {
            width: 100%;
            font-size: 12pt;
            -webkit-print-color-adjust: exact;
            background:url('../img/labelDots.png') repeat-x left center !important;
            background-size: 2pt 8pt !important;
            h3 {
                background: #fff !important;
                color: #730330 !important;
            }
        }

        .onTapMenu-list {
            // page-break-inside: avoid;

            // ul {
            //     columns: 350px 2;
            // }

            ul {
                width: 100%;

                li,
                .selection {
                    page-break-inside: avoid;
                    margin: 0 auto;

                    .selection-title {
                        font-size: 9pt;

                        span {
                            position: relative;
                            //padding: 0;
                            z-index: 1;

                            & + span {
                                &:before {
                                    left: auto;
                                    right: 0;
                                }
                            }

                            &:before {
                                content: '';
                                width: 100%;
                                position: absolute;
                                bottom: 3px;
                                height: 100%;
                                border-bottom: 1px solid #fff; //red;
                                display: block;
                                z-index: -1;
                                left: 0;

                                //content: '';
                                // width: 100%;
                                // position: absolute;
                                // height: 1px;
                                // border: 1px dotted #575757;
                                // border-bottom: 0;
                                // top: 1em;
                                // display: block;
                            }
                        }
                        &:before {
                            //border: 1px dotted #575757;
                            top: 1em;
                            z-index: -2;
                        }
                    }

                    .selection-info {
                        font-size: 8pt;
                    }
                }

                &.containers {
                    li {
                        .selection-title {
                            span + span {
                                &:before {
                                    bottom: 5px;
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}