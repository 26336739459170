/* ==========================================================================
	Quick links at the bottom of pages
========================================================================== */

.quickLinks{
	position: relative;
	margin:0 auto;
	background-color:$tan;

	ul{
		list-style-type: none;
		text-align:center;
		padding:0;
		margin:0 auto;
		max-width:1800px;
		@include clearfix;
	}

	li{
		background-repeat:no-repeat;
		background-position:center;
		background-size:cover;

		@include breakpoint($medium){
			display: inline-block;
			float:left;
			vertical-align:top;
			width:50%;
		}

		&.featuredLinkEvents{
			background-image:url('../img/featuredEvents-bg.jpg');
		}
		&.featuredLinkContact{
			background-image:url('../img/featuredContact-bg.jpg');
		}
		&.featuredLinkAbout{
			background-image:url('../img/featuredAbout-bg.jpg');
		}
		&.featuredLinkBlog{
			background-image:url('../img/featuredBlog-bg.jpg');
		}
		&.featuredLinkOnTap{
			background-image:url('../img/featuredOntap-bg.jpg');
		}

		&:hover{
			opacity:0.7;
		}
	}

	a{
		text-decoration:none;
		color:$darkgray;
		text-transform:uppercase;
		font-family:$sans-serif;
		@include adjust-font-size-to(44px);
		font-weight:700;
		display: block;
		padding:30px;
		max-height:300px;

		@include breakpoint($medium){
			padding:20%;
		}

		@include breakpoint($large){
			padding:0;
			height:300px;
			line-height:300px;
		}
	}
}