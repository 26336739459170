/* ==========================================================================
	Sidebar
========================================================================== */

.sidebar{
	display: none;
	vertical-align: top;
	width:300px;
	position: relative;
	background:#e9dfcb;

	@include breakpoint($large){
		display: inline-block;
		margin:0px 0 0 20px;
		float:right;
		padding:30px 20px;
		width:32.75%;
	}

	@include breakpoint($xxlarge){
		margin-left: 60px;
		width: 36%;
	}
}

.sidebar-info-block{
	position: relative;
	margin: 0 0 rhythm(1.5);
	border-top: 2px dotted $gray;
	padding-top:15px;
	width:240px;
	@include font-smoothing;

	.wrapper{
		padding:0;
	}

	.h4{
		margin-top:0;
		font-weight:500;
	}

	p{
		margin-bottom:10px;
	}
}