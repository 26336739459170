/* ==========================================================================
	Boilerplate
========================================================================== */

*, *:before, *:after { box-sizing: border-box; }

::-moz-selection {
	background: #b3d4fc;
	text-shadow: none;
}
::selection { 
	background: #b3d4fc;
	text-shadow: none;
}
audio, canvas, iframe, img, svg, video {
	vertical-align: middle;
}
img {
	display: block;
	margin: 0 auto;
	vertical-align: middle; 
	max-width: 100%;
}
fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}
textarea { 
	resize: vertical;
}

.browserupgrade {
	background: #999;
	color: #fff; 
	margin: 0;
	padding: 0.5em; 
	
	a {
		color: #fff;
	}
}

.hidden {  // Hide + Remove from DOM & Flow
	display: none !important;
}

.visuallyhidden {  // Hide + Remove from Flow + Keep in DOM
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus { 
	clip: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	position: static;
	width: auto;
}

.invisible {  // Hide + Keep in DOM & Flow
	visibility: hidden;
}