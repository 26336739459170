/* ==========================================================================
	cooler display
========================================================================== */

.coolerDisplay, .page-cooler{
	background: $cream url('../img/coolerdisplay-bg.png') no-repeat top center;

	.siteHeader{
		background:transparent;
		height: 110px;

		.wrapper{
			padding:18px 0;
		}
	}

	.logo{
		width:227px;
		position: relative;
		margin:0 auto;
		left:0; top:0;

		a{
			height:75px;
			background-size:227px;
		}
	}
}

.coolerColumns{
	max-width:845px;
	margin:0 auto;
	@include clearfix;

	.colLeft{
		display: inline-block;
		vertical-align:top;
		width:400px;
		position: relative;
		float:left;
	}

	.colRight{
		display: inline-block;
		vertical-align:top;
		width:400px;
		position: relative;
		float:right;
	}

	.coolerType{
		margin-bottom:rhythm(1);
	}

	.coolerHeading{
		text-align:center;
		color:$purple;
		text-transform:uppercase;
		font-family:$sans-serif;
		display: block;
		position: relative;
		background:url('../img/labelDots.png') repeat-x left center;
		background-size:3px 12px;
		margin-bottom:5px;

		p{
			margin:0;
			background:$cream;
			padding:0 5px;
			display: inline-block;
			line-height:16px;
		}
	}

	.emptyHeading{
		background:transparent;
	}

	.stripe{
		background:transparent;
	}

	td{
		border:0;
		padding:0 0 5px;
		margin:0;
		border-collapse:collapse;

		&:nth-of-type(2){
			text-align:right;
		}
	}

	.coolerDetails{
		position:relative;

		p{
			margin:0;
			line-height:18px;
		}

		.size{
			@include adjust-font-size-to(10px);
			line-height:12px;
		}

	}

	.none{
			display: none;
		}
}