/* ==========================================================================
	Header
========================================================================== */

.siteHeader {
	display: block;
	position: relative;
	background:rgba($cream, 0.9);
	min-height:52px;
	z-index: 201;

	@include breakpoint($medium){
		min-height:60px;
	}

	@include breakpoint($large){
		height:70px;
		background: transparent;
	}

	.wrapper{
		position: relative;
		padding:6px 5px;
		margin:0 auto;

		@include breakpoint($large){
			padding: 0;
		}
	}
}

.navOpen{
	.siteNavigation{
		-webkit-overflow-scrolling: touch;
	}

}

.open{
	position: fixed;
	width:100%;

	@include breakpoint($large){
		position: relative;
	}
}
.logo {
	display: block;
	margin:0 auto;
	width:120px;

	@include breakpoint($medium){
		width:145px;
	}

	@include breakpoint($large){
		position: absolute;
		left:44%; top:10px;
		z-index:100;
		width:158px;
	}

	a {
		display:block;
		text-indent:-9999px;
		margin:0 auto;
		height:40px;
		background:url('../img/logo-header.png') no-repeat center;
		background-image:url('../img/logo-header.svg'), none;
		background-size:120px;
		transition:all 0.2s ease;

		@include breakpoint($medium){
			height:48px;
		}

		@include breakpoint($large){
			height:58px;
			background-size:158px;
		}

		&:hover{
			opacity:0.6;
		}
	}
}
.menuToggle {
	height:35px;
	width:35px;
	right:17px; top:17px;
	position: absolute;
	text-indent:-9999px;
	z-index:900;

	span{
		display: block;
		position: absolute;
		height:2px;
		width:100%;
		background:#000;
		color:#fff;
		border-radius:9px;
		opacity:1;
		left:0;
		transform:rotate(0deg);
		transition:all 0.25s ease-in-out;
		text-indent: -9999px;

		&:nth-child(1){
			top:0;
		}

		&:nth-child(2), &:nth-child(3){
			top:9px;
		}

		&:nth-child(4){
			top:18px;
		}
	}

	@include breakpoint($large) {
		display:none;
	}
}
.menuClose {
	span {
		background:#fff;

		&:nth-child(1) {
			top:18px;
			width:0;
			left:50%;
		}

		&:nth-child(2) {
			transform:rotate(45deg);
		}

		&:nth-child(3) {
			transform:rotate(-45deg);
		}
		&:nth-child(4) {
			width:0;
			left:50%;
		}
	}
}

.email-modal-trigger {
  display: none;
}
.fancybox-skin {
	padding: 20px;
	background: $cream !important;
	color: $darkgray;
	font-size: 1rem;
	border-radius: 4px;
}
.email-modal {
  display: none;
  max-width: 610px;
	min-width: 450px;
	width: 100%;
	padding: 20px 40px 40px;
	.h3 {
		margin-top: 0;
		text-transform: uppercase;
		padding-bottom: 3px;
    margin-bottom: 3px;
    font-size: 1.5em;
    line-height: 30px;
    font-weight: 500;
	}
	.newsletterSubmit{
		background: $purple;
		padding: 10px 35px;
		display: inline-block;
		margin: 12px auto;
		color: #fff;
		text-transform: uppercase;
		font-family: $sans-serif;
		border: 0;
		border-radius: 0;
		font-weight: 500;
		transition: all 0.2s ease;

		&:hover{
			color: #fff;
			background:$yellow;
		}
	}
	.emailLogo {
		margin: 0 auto 24px;
	}
}