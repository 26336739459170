/* ==========================================================================
	Sidebar navigation
========================================================================== */

.sectionNavigation{
	display: none;
	position: relative;

	@include breakpoint($large){
		display: block;
		width:240px;
	}

	&:before{
		content:'';
		display: block;
		background:url('../img/stamp.png') no-repeat right top;
		position: absolute;
		width:114px;
		height:114px;
		top:-20px; right:-10px;
	}

	h2{
		border-bottom: 2px dotted $gray;
		text-transform:uppercase;
		margin:25px 0 5px;
		font-weight:700;

		a{
			text-decoration:none;
			color:$gray;
			padding:0 5px;
			display: block;

			&:hover{
				color: $purple;
			}
		}
	}

	ul{
		padding:0;
		list-style-type: none;

		li{
			position: relative;

			&:last-child{
				a{
					border-bottom:0;
				}
			}
		}

		a{
			display: block;
			color:$gray;
			text-decoration:none;
			padding:14px 5px;
			border-bottom:1px solid #d8d0bf;

			&.hasChildren{
				padding-right:35px;
			}

			&.sectionPlus{
				padding:0;
				position: absolute;
				top:14px; right:5px;
				width: 30px;
				height:30px;
				border:0;
				transition:(all 0.5s ease);
				z-index: 10;
				background:url('../img/icon-plus.png') no-repeat center;
				background-image: url('../img/icon-plus.svg'),none;
				background-size:20px;
				transition:(all 0.5s ease);
			}

			&.sectionPlusOpen{
				transform:rotate(135deg);
				transition:(all 0.5s ease);
			}

			&:hover{
				color: $purple;
			}
		}

		ul{
			display: none;

			a{
				padding: 10px 5px 10px 20px;
			}
		}
	}
}