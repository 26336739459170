/* ==========================================================================
	Typography
========================================================================== */

//@import "compass/typography/vertical_rhythm";
//@import "compass/layout/grid-background";

$base-font-size: 16px;
$base-line-height: 24px;
$round-to-nearest-half-line: true;

@include establish-baseline;

html {
	color: #575757;
	//@include baseline-grid-background(11px, lighten($cyb-blue,55%));
}
body { 
	font-family:$serif;
	position:relative;
	background:$cream;
}

h1, .h1 {
	@include adjust-font-size-to(48px);
	margin:rhythm(1,48px) auto rhythm(1,48px);
	font-family:$sans-serif;
	font-weight:700;
	text-transform:uppercase;
}
h2, .h2 {
	@include adjust-font-size-to(36px);
	margin:rhythm(1.5,36px) auto rhythm(0.5,36px);
	font-family:$sans-serif;
	font-weight:300;
	text-transform:uppercase;
}
h3, .h3 {
	@include adjust-font-size-to(24px);
	margin:rhythm(1.6,24px) auto rhythm(0.4,24px);
	font-family:$sans-serif;
	font-weight:300;
}
h4, .h4 {
	@include adjust-font-size-to(18px);
	margin:rhythm(1.75) auto rhythm(0.25);
	font-family:$sans-serif;
	font-weight:300;
}
h5, .h5 {
	@include adjust-font-size-to(18px);
	margin:rhythm(1.75) auto rhythm(0.25);
	font-family:$serif;
	font-weight:700;
}
.pageTitle{
	color:#ffffff;
	font-family:$sans-serif;
	text-transform:uppercase;
	@include adjust-font-size-to(36px);
	font-weight:700;
	letter-spacing:1px;
	margin:0 auto;

	@include breakpoint($medium){
		@include adjust-font-size-to(50px);
	}

	@include breakpoint($large){
		@include adjust-font-size-to(72px);
	}
}
p {
	margin:0 auto rhythm(1);
}
a {
	color:$purple;
	text-decoration: underline;
	transition: color 0.2s ease;

	&:hover {
		text-decoration:none;
		color:lighten($purple,30);
	}
}
ul, ol {
	margin:0 0 rhythm(1,14px);

	ul, ol {
		margin:0;
	}
}
blockquote {
	@include adjust-font-size-to(24px);
	font-family:$serif;
	padding:rhythm(1,24px);
	margin:0 auto rhythm(1,24px);
	background:$tan;

	@include breakpoint($xlarge){
	//	margin:0 0 rhythm(1,24px) -70px;
	//	padding:rhythm(2,24px) 0;
	//	width:115%;
	}

	p{
		margin: 0 auto;
	}

}
table {
	width:100%;
	margin:0 auto rhythm(1);
}
tbody {
	border:none;
}
.stripe{
	background: #f7f3e9;
}
thead{
	tr{
		background: $yellow;
	}
}
tr:nth-child(even) td {
	background: #f7f3e9;
}
th {
	text-align:left;
	text-transform:uppercase;
	padding:0 1em;
	border:none;
}
td {
	padding:10px 1em 11px;
	vertical-align:top;
	border:1px solid #d8d0bf;

	&:first-of-type{
		border-right:1px solid #d8d0bf;
	}
}

.swipeAction{
	background:$tan;
	color:$gray;
	line-height:40px;
	padding:0 10px 0 10px;
	position:relative;
	text-align:center;
}
 
.tableWrapper{
	overflow:scroll;

	@include breakpoint($medium){
		overflow:auto;
	}
}

img {
	margin-bottom:rhythm(1);
}
hr{
	border:0;
	border-bottom:2px dotted $gray;
}

.spoiler {
	color: #0000FF;
}