/* ==========================================================================
	Featured on Tap on homepage and whats on tap page
========================================================================== */

.featuredOnTap{
	position: relative;
	background: #e7e0d2 url('../img/singleStamp-bg.png') no-repeat center top;
	padding: 20px;
	text-align: center;

	@include breakpoint($large){
		padding: 60px 0 5px;
	}

	.wrapper{
		@include clearfix;

		@include breakpoint($large){
			width: 830px;
			margin: 0 auto rhythm(2);
			position: relative;
		}
	}

	.ontapBadge{
		@include breakpoint($medium){
			margin: 15px auto;
		}

		@include breakpoint($large){
			width: 135px;
			position: absolute;
			left: 43%;
			height:85%;
			margin: 1em 0;
		}

		hr{
			display: none;

			@include breakpoint($large){
				display: block;
				width:1px;
				height: 142px;
				margin: 0 auto;
				padding:0;
				border-left: 2px dotted $gray;
				border-bottom:0;
			}
		}

		img{
			@include breakpoint($large){
				margin: 1em auto;
			}
		}

	}

	.featured{
		position: relative;
		width: rem(280px);
		margin: 0 auto rhythm(2);
		text-align: center;

		@include breakpoint($medium){
			width:45%;
			display: inline-block;
			float:left;
			vertical-align:top;
		}

		@include breakpoint($large){
			width:330px;
			margin: rhythm(2) auto;
		}

		&:last-of-type{
			@include breakpoint($medium){
				float:right;
			}
		}

		a{
			color:$gray;
			transition:(all 0.2s ease);
			display: block;

			&:hover{
				.name{
					color:$purple;
				}
			}
		}

		.fancybox{
			img{
				max-height:180px;
				width: auto;
				margin:0 auto;
				box-shadow:1px 1px 2px $gray;
			}
		}


		.name{
			@include adjust-font-size-to(24px);
			text-transform:none;
			font-weight:500;
		}

		p{
			margin-bottom:rhythm(0.5);

			&.price{
				display: inline-block;
				vertical-align:top;
				width: 41%;
			}
		}

		.field-collection-container{
			border-bottom:0;
			margin-bottom:0;
		}

		hr{
			width:75%;
		}
	}


}
