/* ==========================================================================
	Staff Listing page
========================================================================== */
.staff-list-box{
	margin:0 auto rhythm(1);
	width:270px;
	@include font-smoothing;

	@include breakpoint($medium){
		display: inline-block;
		vertical-align:top;
		width:45%;
		margin-right:20px;
	}

	@include breakpoint($large){
		width:185px;
	}
}
.staff-list{
	position: relative;

	img{
		margin:0;
		display: block;
	}

	.staff-name{
		margin:10px 0 0;
	}

	.staff-title{
		font-weight:600;
		margin:0;
		font-family:$sans-serif;
		@include adjust-font-size-to(12px);
	}

}