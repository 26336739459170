/* ==========================================================================
	On Tap
========================================================================== */

.node-type-on-tap-page {
  .siteHeader {
    background-color: #BDAA83;

    @include bp($large) {
      background: transparent;
    }
  }
  .siteNavigation {
    @include bp($large) {
      background-color: #BDAA83;
    }
  }
}

html {
  body.node-type-beer,
  body.node-type-wine {
    margin: 0 !important;
    padding: 0 !important;
    #admin-menu {
      display: none;
    }
  }
}

.bottleSizes{
	position: relative;
	background: #000 url('../img/onTapbg.jpg') no-repeat center top;
	background-size:cover;

	.wrapper{
		margin:0 auto;
		padding: 52px 0px 40px;
		position: relative;

		@include breakpoint($medium){
			padding: 70px 0 30px;
		}

		@include breakpoint($large){
			max-width:980px;
		}
	}

	.titleArea{
		color:$cream;
		text-align:center;
		padding: 52px 20px 20px;

		@include breakpoint($large){
			max-width:650px;
			margin: 0 auto;
		}

		h2{
			@include adjust-font-size-to(36px);
		}

		p{
			@include adjust-font-size-to(18px);
		}
	}

	.sizes{
		position: relative;
		@include breakpoint($large){
			max-width: 940px;
			margin:0 auto;
		}
	}
}

.bottle-carousel {
  list-style: none;
  margin: 0;
  padding: 0 0 rem(75px);
  @include bp($medium) {
    padding: 0;
  }
  &__item {
    display: block;
    color:$cream;
    text-align:center;
    outline: 0;
    img {
      margin: 0;
      @include breakpoint($large){
        width:auto;
      }
    }
    .h2 {
      @include adjust-font-size-to(24px);
    }
    p {
      margin:0 auto;
    }
    hr {
      border-color:$cream;
      width:33%;
    }
  }
  .slick-arrow {
    overflow: hidden;
    z-index: 1;
    display: block;
    position: absolute;
    bottom: 0;
    width: rem(20px);
    height: rem(35px);
    padding: 0;
    text-indent: -9999px;
    border: 0;
    outline: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    transition: opacity 0.3s;
    @include bp($medium) {
      bottom: 50%;
      width: rem(15px);
      transform: translate3d(0,50%,0);
    }
    &.slick-prev {
      right: 50%;
      margin-right: rem(40px);
      background-image: url('../img/icon-angle-left-cream.png');
      background-image: url('../img/icon-angle-left-cream.svg');
      @include bp($medium) {
        right: auto;
        left: rem(10px);
        margin: 0;
      }
    }
    &.slick-next {
      left: 50%;
      margin-left: rem(40px);
      background-image: url('../img/icon-angle-right-cream.png');
      background-image: url('../img/icon-angle-right-cream.svg');
      @include bp($medium) {
        left: auto;
        right: rem(10px);
        margin: 0;
      }
    }
    &.slick-disabled {
      opacity: 0.3;
    }
  }
}

.on-tap-listing-header {
  margin: 0 0 rem(40px);
  padding: rem(40px) rem(20px) 0;
  text-align: center;
  & > h2 {
    margin: 0 0 rem(10px);
  }
}
.on-tap-listing-subtitle {
  font-weight: 500;
  font-size: rem(16px);
  line-height: 1.4;
  @include bp($medium){
    font-size: rem(18px);
  }
}
.closing-notice {
  p:not(.h2) {
    font-size: 20px;
  }
}
.beerList{
	position: relative;

	.beer-filters{
    max-width: rem(280px);
		padding: 0 0 rem(20px);
		margin:0 auto 20px;
		text-align:center;
		border-bottom:2px dotted $gray;

		@include breakpoint($medium){
			max-width:705px;
			margin: 0 auto 50px;
		}

		.views-submit-button{
			display: none;
		}

		.views-exposed-form{
			.views-exposed-widget{
				float:none;
				padding:0;
			}
		}

		.views-exposed-widgets{
			margin-bottom:0;
		}
	}

	.filter-button{
    border:1px solid $gray;
    margin: 0 0 rem(30px);
		padding: 10px 50px;
		display: none;
		color:$gray;
		@include adjust-font-size-to(18px);
		transition:(all 0.3s ease);
		@include font-smoothing;
		&:hover{
			background:$gray;
			color:#fff;
		}
	}

	.views-widget-filter-field_beer_and_wine_categories_tid{
		> label{
			display: none;
		}
	}

	.form-checkboxes{
    text-align:left;
    margin: 0;
  }
  .bef-checkboxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .form-type-bef-checkbox{
    width: 48%;
    margin: 0 0 rem(15px);

    @include breakpoint($medium){
      width:32%;
    }

    @include breakpoint($large){
      width: 24%;
    }

    label {
      font-weight: 500;
      font-size: rem(14px);
      line-height: rem(20px);
      @include bp($medium){
        font-size: rem(18px);
      }
    }
  }
}


.beers {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  max-width: rem(280px);
  margin: 0 auto;

  @include breakpoint($small){
		max-width: rem(440px);
	}

	@include breakpoint($medium){
		max-width:720px;
	}

	@include breakpoint($large){
		max-width:960px;
		padding:0;
	}

	.backtoTop{
		display: block;
    position: relative;
    width: 100%;
    margin: 0.5em auto 2em;
    text-align:center;
		color:$gray;
		@include font-size(18px);

		&:hover{
			color:$purple;
		}

		&:before{
			content: '';
			border-top: 2px dotted $gray;
      position: absolute;
      top: 12px;
			right: 50%;
			width: 25%;
			margin: 0 rem(50px) 0 0;
			@include breakpoint($large){
				width: 20%;
			}
		}

		&:after{
			content: '';
			border-top: 2px dotted $gray;
      position: absolute;
      top: 12px;
			left: 50%;
      width: 25%;
      margin: 0 0 0 rem(50px);
			@include breakpoint($large){
				width: 20%;
			}
		}
	}
}

.view-empty{
	h3{
		text-align:center;
		margin: 1.5em auto;
	}
}

.beer-pop {
	padding: 20px;
}

.beer {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto rhythm(1);
  border-radius: rem(3px);
	border: 1px solid #979797;
  transition:(all 0.3s ease);
  @include font-smoothing;
	@include bp($medium) {
    width: 48%;
    margin: 0 4% 4% 0;
	}
	@include bp($large) {
    width: 31%;
    margin: 0 3.5% 3.5% 0;
  }
  &:nth-of-type(2n) {
    @include bp($medium) {
      margin: 0 0 4%;
    }
    @include bp($large) {
      margin: 0 3.5% 3.5% 0;
    }
  }
  &:nth-of-type(3n) {
    @include bp($large) {
      margin: 0 0 3.5%;
    }
  }
  *, *:before, *:after {
    @include font-smoothing;
  }
  &__tags {
    min-height: rem(20px);
    background-color: #E5DECE;
  }
  &__tag-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  &__tag-item {
    padding: rem(3px) rem(5px);
    color: #FFF;
    text-transform: uppercase;
    letter-spacing: rem(1px);
    font-family: $sans-serif;
    font-size: rem(14px);
    line-height: rem(14px);
    border-radius: rem(2px);
    background-color: $purple;
  }
  &__top {
    display: flex;
    background-color: #E5DECE;
    @include bp($medium) {
      min-height: rem(140px);
    }
  }
  &__top-left {
    padding: rem(10px) rem(10px) rem(20px) rem(20px);
  }
  &__image {
    overflow: hidden;
    display: block;
    min-width: rem(130px);
    min-height: rem(130px);
    text-indent: -9999px;
    background-color: #FFF;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('/sites/all/themes/atthetap/img/beer-default.png');
    box-shadow: 0px 0px rem(12px) rgba(0,0,0,0.2);
    img {
      max-width: rem(110px);
      height: auto;
      margin: 0;
    }
  }
  &__top-right {
    padding: rem(10px) rem(20px) rem(20px) rem(10px);
  }
  &__prices {
    .price {
      margin: 0;
      font-size: rem(16px);
      line-height: 1.3;
    }
  }
  &__stats {
    margin: rem(10px) 0 0;
  }
  &__stat {
    margin: 0;
    font-weight: bold;
    font-size: rem(16px);
    line-height: 1.3;
  }
  &__bottom {
    flex: 1;
    position: relative;
    padding: rem(16px) rem(20px) rem(71px);
  }
  &__name {
    margin: 0 0 rem(5px);
    color: #000;
    font-weight: 600;
    font-size: rem(22px);
    line-height: 1.3;
    a {
      color: #000;
      transition: color 0.3s;
      &:hover {
        color: $purple;
      }
    }
  }
  &__description {
    margin: 0;
    color: #000;
    font-size: rem(16px);
    line-height: 1.2;
  }
  .more-link {
    display: block;
    position: absolute;
    bottom: rem(20px);
    left: rem(20px);
    right: rem(20px);
    padding: rem(10px) 0;
    text-align: center;
    color: #FFF;
    font-family: $sans-serif;
    font-weight: 600;
    font-size: rem(16px);
    line-height: rem(16px);
    border-radius: rem(3px);
    background-color: $purple;
    transition: background-color 0.3s;
    &:hover {
      background-color: lighten($purple,10);
    }
  }
  &.wine {
    .beer__image {
      background-image: url('/sites/all/themes/atthetap/img/wine-default.png');
    }
  }
  &.full {
    width: auto;
    margin: 0 auto;
    border: 0;
    border-radius: 0;
    .beer__image {
      @include bp(440px) {
        min-width: rem(200px);
        min-height: rem(200px);
      }
      img {
        @include bp(440px) {
          max-width: rem(200px);
        }
      }
    }
    .beer__prices {
      .price {
        @include bp(440px) {
          font-size: rem(18px);
        }
      }
    }
    .beer__stat {
      @include bp(440px) {
        font-size: rem(18px);
      }
    }
    .beer__bottom {
      padding: rem(16px) rem(20px) rem(20px);
    }
    .beer__name {
      @include bp(440px) {
        font-size: rem(24px);
        text-transform: none;
      }
    }
    .beer__description {
      @include bp(440px) {
        font-size: rem(18px);
      }
    }
  }
}

.openBeer{
	height:100% !important;
}

.staff-pick {
	padding: 0 20px;
	background-color: #E2DACF;
	&__container {
		max-width: 280px;
		margin: 0 auto;
		padding: 40px 0;
		@include clearfix;
		@include bp($small) {
			max-width: 440px;
		}
		@include bp($medium) {
      max-width: 820px;
      min-height: rem(353px);
		}
	}
}
.staff-pick-beverage {
	position: relative;
	margin: 0 0 20px;
	padding: 20px 30px;
	text-align: center;
	background-color: $cream;
	@include bp($medium) {
		float: left;
		width: 40%;
		margin: 0;
		padding: 30px 40px;
	}
	&:after {
		content: '';
		display: none;
		position: absolute;
		top: 0;
		left: 100%;
		width: 25px;
		height: 100%;
		background-color: $cream;
		@include bp($medium) {
			display: block;
		}
	}
	img {
		width: 110px;
		height: auto;
	}
	&__name {
		margin: 0 0 10px;
		text-transform: none;
		font-weight: normal;
		font-size: 28px;
		line-height: 1.3;
	}
	&__description {
		margin: 0;
		line-height: 1.2;
	}
	.more-link {
		margin: 15px 0 0;
	}
}
.staff-pick-person {
	position: relative;
	display: block;
	width: auto;
	margin: 0;
	@include bp($small) {
		min-height: 306px;
		padding: 0 0 0 55%;
	}
	@include bp($medium) {
		float: right;
		width: 30%;
		min-height: 0;
		margin: 40px 0 0;
		padding: 0;
	}
	.staff-list {
		position: static;
		margin: 0 0 20px;
		@include bp($small) {
			padding: 20px 0 0;
		}
		@include bp($medium) {
			padding: 0;
		}
		img {
			width: 100%;
			margin: 0;
		}
		.staff-photo {
      overflow: hidden;
			position: relative;
			margin: 0 auto 20px;
			@include bp($small) {
				position: absolute;
				top: 0;
				left: 0;
				width: 50%;
				margin: 0;
			}
			@include bp($medium) {
        top: rem(-40px);
				left: auto;
				right: 100%;
        width: rem(197px);
        height: rem(273px);
				margin: 0 30px 0 0;
			}
		}
		.staff-name {
			margin: 0 0 5px;
			font-weight: normal;
			font-size: 32px;
			line-height: 1.3;
		}
		.staff-title {
			font-weight: normal;
			font-size: 18px;
			line-height: 1.3;
		}
	}
	&__quote {
		margin: 0;
		font-size: 18px;
		line-height: 1.3;
	}
}
.scroll-to-filters {
  opacity: 0;
  visibility: hidden;
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: rem(10px) 0;
  color: #FFF;
  font-weight: bold;
  font-size: rem(16px);
  line-height: 1;
  border: 0;
  outline: 0;
  background-color: $purple;
  transition: opacity 0.3s, visibility 0.3s;
  @include font-smoothing;
  @include bp($medium) {
    display: none;
  }
  &.active {
    opacity: 1;
    visibility: visible;
  }
}
