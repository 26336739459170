/* ==========================================================================
	Layout
========================================================================== */

.container {
	margin:0 auto;
	position:relative;

	@include breakpoint($large){
		background:url('../img/stamp-bg.png') no-repeat center -50px;
		background-size:1795px;
	}
	

	.wrapper{
		@include clearfix;
		max-width:1024px;
		padding:30px 10px 20px;
		position: relative;
		margin:0 auto;

		@include breakpoint($large) {
			max-width:960px;
			padding:30px 0;
		}
	}

	.hasSidebar{

		@include breakpoint($large){
			padding:0 0 30px;
			max-width:100%;
		}

		.mainColumn{
			@include breakpoint($large){
				float:right;
				display: inline-block;
				vertical-align: top;
				padding-top:30px;
			}

			hr{
				@include breakpoint($large){
					margin: 0 -25px 1em -25px;
		    		width: 108%;
				}
			}

			blockquote{
				@include breakpoint($large){
					margin: 0 -25px 1em -25px;
		    		width: 105%;
				}

				@include breakpoint($xlarge){
					width:108%;
				}
			}

			table{
				@include breakpoint($large){
					margin: 0 -25px 1em -25px;
		    		width: 105%;
				}

				@include breakpoint($xlarge){
					width:108%;
				}
			}
		}

	}
}

.pageTitleContainer{
	position: relative;
	min-height:176px;
	height:176px;
	margin-top:-52px;
	background:url('../img/pagetitle-bgimg.png') no-repeat center;
	background-size:cover;
	display: table;
	width:100%;

	@include breakpoint($medium){
		min-height:200px;
		margin-top:-60px;
	}

	@include breakpoint($large){
		min-height:291px;
		margin-top:-70px;
	}

	.pageTitlebox{
		padding-top:52px;
		height:100%;
		min-height:176px;
		text-align:center;
		display: table-cell;
		vertical-align:middle;
	}
}

.mainColumn{
	max-width:640px;
	margin: 0 auto;

	table{
		@include breakpoint($medium){
			margin: 0 -30px 1em -30px;
    		width: 110%;
		}
		@include breakpoint($large){
			margin: 0 -60px 1em -60px;
    		width: 120%;
		}
	}

	hr{
		@include breakpoint($medium){
			margin: 0 -30px 1em -30px;
    		width: 110%;
		}
		@include breakpoint($large){
			margin: 0 -60px 1em -60px;
    		width: 120%;
		}
	}

	blockquote{

		@include breakpoint($medium){
			margin: 0 -30px 1em -30px;
    		width: 110%;
		}
		@include breakpoint($large){
			margin: 0 -60px 1em -60px;
    		width: 120%;
		}
	}
}





