/* ==========================================================================
	event / blog listing page
========================================================================== */

.filterTop{
	position: relative;
}

.view-events, .view-blog-posts{
	.views-exposed-form{
		.views-exposed-widget{
			float:none;
			padding:0;
		}

		.form-item{
			margin: 0 0 1em;
		}
	}
}

.filters{
	position:relative;

	.choosecategory{

		@include breakpoint($medium){
			display: inline-block;
			vertical-align:top;
			width:40%;
			margin-right:45px;
		}
	}


	.selectStyle{
		margin:0;
	}

	p{
		display: block;
		text-align:center;
		@include adjust-font-size-to(18px);

		@include breakpoint($medium){
			display: inline-block;
			vertical-align: top;
			line-height:40px;
			margin:0 10px;
		}
	}
}

.datefilter{
	width:45%;
	display: inline-block;
	vertical-align:top;

	@include breakpoint($medium){
		width:25%;
	}

	.container-inline-date{
		.date-padding{
			float:none;
		}

		.selectStyle{
			&:after{
				content:'';
				padding:0;
				font-size:0;
			}
		}

		.form-item{
			width:100%;
			float:none;
			margin:0;

			input{
				width:100%;
				margin:0;
				cursor:pointer;
				border:0;
				background:url('../img/date-caret.png') no-repeat 93% 55%;
				background-size:10px;
				padding:11px;

				&:focus{
					outline:0;
					border:0;
				}
			}
		}
	}

	&:nth-of-type(3){
		float:right;
	}
}
.listoutput{
	padding:10px 0;
}

.listarticle{
	position: relative;
	margin: 0 auto rhythm(1);

	.category{
		margin-bottom:8px;
	}

	img{
		margin:0;
		transition:(opacity 0.2s ease);

		&:hover{
			opacity:0.7;
		}
	}

	.articleTitle{
		margin:24px 0 10px;

		a{
			color:$gray;

			&:hover{
				color:$purple;
			}
		}
	}

	.time{
		@include breakpoint($small){
			float:right;
		}
	}

	.readMore{
		display: block;
		margin-bottom:rhythm(1);
	}
}

.item-list{
	text-align: center;

	.pager{
		padding:0;
		list-style-type: none;
		@include clearfix;
		font-family:$sans-serif;
		display: inline-block;
		margin:0 auto rhythm(1);

		li{
			display: inline-block;
			float:left;
			text-transform:uppercase;
			margin: 5px;
			@include adjust-font-size-to(16px);
			padding:0;

			&.pager-item{
				background:$tan;
			}

			&.pager-current{
				padding: 2px 10px;
				font-weight:500;
			}

			&.pager-previous{
				a{
					color:$gray;

					&:hover{
						color:$purple;
					}
				}
			}

			&.pager-last{
				display: none;
			}

			&.pager-first{
				display: none;
			}
		}

		a{
			color:$gray;
			text-decoration:none;
			padding: 2px 10px;
			display: block;

			&:hover{
				color:$purple;
			}
		}
	}
}