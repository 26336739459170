/* ==========================================================================
	Social Feed
========================================================================== */


.socialArea{
	overflow: hidden;
	position: relative;
	@include clearfix;

	@include breakpoint($medium){
		max-height: 720px;
		height:auto;
	}

	.socialFeed{
		overflow: auto;
		margin: 0 auto;
		padding: 0;
		@include clearfix;
		list-style-type: none;
		height: auto;
		background: $yellow;

		@include breakpoint($medium){
			overflow: hidden;
		}

		ul{
			width: 2400px;
			margin:0 auto;
			padding:0;
		}
	}

	li {
		position: relative;
		height: 240px;
		width: 240px;
		display: inline-block;
		float:left;
		overflow:hidden;
		background:$darkgray;

		&.light {

			.caption {
				background: rgba(lighten($purple,3),0.8);
			}

		}

		&.noImage {

			.caption {
				opacity: 1;
				background: $purple;
			}

			&.light {

				.caption {
					background: lighten($purple,3);
				}

			}
			
		}

		.postBlock {
			display: block;
			text-decoration:none;
			background-repeat:no-repeat;
			background-position: center;
			background-size:cover;
			opacity:0.9;
			position: absolute;
			height: 100%;
			width: 100%;
			transition:(all 0.5s ease-in-out);

			&:hover{
				width:115%;
				height:115%;

				.caption{
					display: block;
					opacity:1;
				}
			}

		}

		.imgHolder{
			background-repeat:no-repeat;
			background-position: center;
			background-size:cover;
			opacity:0.9;
			position: absolute;
			height: 100%;
			width: 100%;
		}

		.caption{
			position: relative;
			width: 240px;
			height: 240px;
			top:0; left:0;
			background: rgba($purple, 0.8);
			color:$cream;
			overflow:auto;
			padding: 15px;
			@include font-smoothing;
			opacity:0;
			transition:(opacity 0.4s ease);
		}

		.username {
			color: $yellow;
		}
		
	}

	.visitSocial{
		padding: 30px 20px;
		display: inline-block;
		width: 100%;
	//	min-width:720px;
		background:$yellow;
		color:#000;
		@include font-smoothing;
		height: 240px;
		z-index:20;

		@include breakpoint($medium){
			max-width:720px;
			position: absolute;
			bottom: 0;
			right:0;
			width:100%;
		}

		.wrapper{
			
			width: 100%;
		//	max-width:300px;
			position: relative;

			@include breakpoint($medium){
				max-width: 536px;
				margin: 0 auto;
			}
		}

		.h2{
			margin:20px 0 0;
			font-weight: 400;

			@include breakpoint($medium){
				margin-top:50px;
			}
		}

		.h3{
			margin:0;
		}
	}
}


