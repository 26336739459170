/* ==========================================================================
	Homepage
========================================================================== */

.mmaSection{
	position: relative;
	margin-top:-52px;
	background-color:$yellow;

	@include breakpoint($medium){
		margin-top:-60px;
		background:transparent;
	}

	@include breakpoint($large){
		margin-top:-70px;
	}

  #homeSlide {
    z-index: 200;
  }

	.owl-wrapper{
		z-index:55;
	}

	.owl-controls{
		text-align:center;
		position: relative;

		@include breakpoint($medium){
			position: absolute;
			width:100%;
			bottom:0;
			z-index:60;
		}
	}

	.owl-pagination, .slick-dots{
		padding: 10px 0 5px;
		position: relative;
		margin: 0 auto;
		z-index:50;
		display: block !important;
		text-align: center;
		
		@include breakpoint($medium){
			position: absolute;
			width:100%;
			bottom:0;
			z-index:60;
		}

	}

	.owl-page, .slick-dots li{
		display: inline-block;
		@include clearfix;

		span, button{
			background:#fff;
			color: #000;
			opacity:1;
			width:16px;
			height:16px;
			display: block;
			margin: 5px 7px;
			border-radius:20px;
			cursor:pointer;
			text-indent:-9999px;
			border:3px solid #fff;
			padding:0;
		}
	}

	.active, .slick-dots li.slick-active{
		span, button{
			background:$yellow;
			border:3px solid #fff;
		}
	}

	.customNavigation{
		position: absolute;
		bottom:0;
		width:100%;

		.navContainer{
			position: relative;
			width:100%;
			height:100%;

			@include breakpoint($medium){
				max-width:700px;
				margin:0 auto;
			}

			@include breakpoint($large){
				max-width:880px;
			}

		}

		.navBox{
			position: absolute;
			width:100%;
			height:100%;
		}

		a{
			display: block;
			position: absolute;
			z-index:58;
			width:50px;
			height:100%;
			transition:(all 0.2s ease);
			text-indent: -9999px;
      z-index: 200;

			&.next{
				right:0;
				background: url('../img/slider-next.png') no-repeat center;
				background-image: url('../img/slider-next.svg'), none;
			}

			&.prev{
				left:0;
				background: url('../img/slider-prev.png') no-repeat center;
				background-image: url('../img/slider-prev.svg'), none;
			}
		}
		
	}
}

.slide{
	position: relative;
	margin:0 auto;
	background: #000;
	//z-index:200 !important;

	@include breakpoint($medium){
		height:650px !important;
	}

	@include breakpoint($large){
		height:800px !important;
	}

	&.has-video {
		@include breakpoint($medium){
			height: auto !important;
			padding: 0 0 42%;
		}
		&:after {
			content: '';
			opacity: 0.3;
			display: none;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: #000;
			@include breakpoint($medium){
				display: block;
			}
		}
		.slideContent {
			@include breakpoint($medium){
				padding: 0;
			}
			.button {
				@include breakpoint($medium){
					margin: 0 auto;
				}
			}
		}
		.slideContentContainer {
			.wrapper {
				@include breakpoint($medium) {
					padding: 20px;
				}
			}
		}
	}

	.background {
		background-color: #000;
		background-repeat:no-repeat;
		background-position: center top;
		background-size:cover;
		position: relative;
		height:385px;
		@include breakpoint($medium){
			height:100%;
			position: absolute;
			width:100%;
		}
		@include breakpoint($notablet){
			background-attachment:fixed;
		}
		iframe {
			display: none;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			@include breakpoint($medium){
				display: block;
			}
		}
	}
	
	
		@supports (-ms-ime-align:auto) {
			.background {
				background-attachment:inherit;
			}
		}
	 

	.slideContentContainer{
		z-index: 1;
		position: relative;
		margin:0 auto;

		@include breakpoint($medium){
			position: absolute;
			width: 100%;
			bottom:0;
		}
		
		.wrapper{
			@include breakpoint($medium){
				position: relative;
				max-width:700px;
				margin: 0 auto;
				padding: 15px 15px;
				background:$yellow;
			}

			@include breakpoint($large){
				max-width:880px;
			}
		}
	}

	.headlineBox{
		max-width:300px;
		margin: 0 auto;
		position: relative;

		@include breakpoint($medium){
			max-width:880px;
		}
	}

	.headline{
		font-family:$sans-serif;
		color:#fff;
		@include adjust-font-size-to(36px);
		font-weight:700;
		text-transform:none;
	//	position: absolute;
	//	bottom:10px;
		position: relative;
		text-align:center;
		margin:0 auto;
		min-width:300px;
		width:100%;

		@include breakpoint($medium){
			@include adjust-font-size-to(65px);
		//	bottom: 40px;
		}

		@include breakpoint($large){
			@include adjust-font-size-to(84px);
			width: 880px;
		}

		span{
			position: absolute;
			bottom:10px;
			width:100%;
			left:0;

			@include breakpoint($medium){
				bottom:40px;
			}

			@include breakpoint($large){
				left:-15px;
			}


		}
	}

	.slideContent{
		position:relative;
		padding: 20px 0;
		color:#000;
		background:$yellow;
		text-align: center;

		@include breakpoint($medium){
			padding: 20px 35px 40px;
			width: 85%;
			margin: 0 auto;
		}

		p{
			color: #000;
			width:230px;
			margin:0 auto;
			@include adjust-font-size-to(14px);

			@include breakpoint($medium){
				@include adjust-font-size-to(16px);
				width: auto;
			}

			@include breakpoint($large){
				@include adjust-font-size-to(18px);
			}
		}

    .button {
      margin: 1em auto 0;
    }
	}


}


.quickInfoSection{
	position: relative;
	padding: 40px 20px 20px;

	@include breakpoint($large){
		padding: 40px 0 20px;
		background: url('../img/address-bg.png') no-repeat center top;
		background-size:1108;
	}

	.wrapper{
		@include clearfix;
		position: relative;
		margin: 0 auto;

		@include breakpoint($large){
			max-width:920px;
		}
	}

	.columnContainer{
		@include clearfix;
		padding-bottom: 20px;
	}

	.infoCol{
		margin:0 auto rhythm(1);
		width: 258px;
		text-align:center;

		@include breakpoint($medium){
			display: inline-block;
			vertical-align: top;
			float:left;
			margin-right: 24px;
			width:29%;
		}

		@include breakpoint($large){
			margin-right: 40px;
			padding-right: 46px;
			min-height: 232px;
			border-right: 1px solid $gray;
			width: 29%;
		}

	}

	.covid-warning {
		color: $purple;
		padding: 5px 0;
	}

	.left{
		@include breakpoint($medium){
			width:35%;
		}

		@include breakpoint($large){
			width: 33%;
		}
	}

	.right{
		@include breakpoint($medium){
			float:right;
			text-align: left;
			margin-right:0;
			border:0;
			padding:0;
		}
	}

	.h3{
		text-transform:uppercase;
		border-bottom:2px dotted $gray;
		padding-bottom:3px;
		margin-bottom:3px;
		@include adjust-font-size-to(24px);
		line-height:30px;
		font-weight:500;
		margin-top:0;
	}

	p, a{
		margin-bottom:0;
		@include adjust-font-size-to(18px);
		line-height:22px;
	}

	.quickSocial{
		display: block;
		width:75%;
		margin:0 auto;
		border-top: 2px dotted $gray;
		padding-top: 20px;
		text-align: center;

		p{
			font-family:$sans-serif;
			text-transform:uppercase;
			@include adjust-font-size-to(14px);

			@include breakpoint($medium){
				display: inline-block;
				vertical-align:top;
				margin-right:20px;
				line-height:30px;
			}

		}

		.social{
			margin: 1em auto;

			@include breakpoint($medium){
				display: inline-block;
				vertical-align:top;
				margin-top:0;
			}

			a{
				opacity:1;
				color: #000;

				&:hover{
					opacity:0.6;
				}
			}
		}

	}

	.twitter{
		a{
			background:url('../img/icon-x-twitter-tan.png') no-repeat center;
			background-image: url('../img/icon-x-twitter-tan.svg'), none;
		}
	}

	.facebook{
		a{
			background:url('../img/icon-facebook-tan.png') no-repeat center;
			background-image: url('../img/icon-facebook-tan.svg'), none;
		}
	}
	.instagram{
		a{
			background:url('../img/icon-instagram-tan.png') no-repeat center;
			background-image: url('../img/icon-instagram-tan.svg'), none;
		}
	}
	.youtube{
		a{
			background:url('../img/icon-youtube-tan.png') no-repeat center;
			background-image: url('../img/icon-youtube-tan.svg'), none;
		}
	}
}



.upcomingEvents{
	background:url('../img/wood-tile.jpg') repeat center top;
	position: relative;
	

	@include breakpoint($large){
		background-attachment:fixed;
	}

	.wrapper{
		@include clearfix;
		padding: 20px;
		text-align: center;
		max-width:980px;
		margin: 0 auto;

		@include breakpoint($large){
			padding: 40px 0;
		}
	}

	.sectionTitle{
		color:#fff;
		font-weight:500;
		text-align:center;
		margin-top:15px;
		@include adjust-font-size-to(30px);
		position: relative;
		display: inline-block;

		&:before{
			@include breakpoint($large){
				content:'';
				border-top:2px dotted #fff;
				width:185px;
				position: absolute;
				left:-195px;
				top: 17px;
			}
		}
		&:after{
			@include breakpoint($large){
				content:'';
				border-top:2px dotted #fff;
				width:185px;
				position: absolute;
				right:-195px;
				top: 17px;
			}
		}
	}

	.eventContainer{
		padding:20px 0;
		@include clearfix;

		@include breakpoint($large){
			padding: 40px 0 0;
		}
	}

	.event{
		position: relative;
		color:#fff;
		width:280px;
		margin:0 auto rhythm(2);
		@include font-smoothing;
		text-align: left;

		@include breakpoint($medium){
			display: inline-block;
			vertical-align: top;
			float:left;
			width:31%;
			margin-right:24px;
		}

		@include breakpoint($large){
			max-width: 285px;
			margin-right:6%;
		}

		&:nth-of-type(3){
			@include breakpoint($medium){
				margin-right:0;
			}
		}

		> a{
			display: block;

			&:hover{
				@include breakpoint($large){
					img{
							opacity:0.7;
							transform: scale(1.1);
						}
				}
			}
		}

		.eventImageContainer{
			@include breakpoint($large){
				overflow:hidden;
				width:285px;
			}
		}

		img{
			margin-bottom:0;
			transition:(all 0.5s ease);
		}

		.title{
			@include adjust-font-size-to(18px);
			margin-top:10px;

			a{
				color:#fff;
				display: block;

				&:hover{
					color:$yellow;
				}
			}
		}

		.date{
			color:$yellow;
			margin-bottom:0;
		}
	}

	.button{
		color:#000;
	}
}


.recentBlogs{
	background:$cream url('../img/doubleStamp-bg.png') no-repeat center top;
	background-size:cover;


	.wrapper{
		padding: 50px 20px 30px;
		@include clearfix;
		margin: 0 auto;
		text-align:center;

		@include breakpoint($large){
			padding: 80px 0 30px;
			max-width:930px;
		}
	}

	.blogContainer{
		@include clearfix;
	}

	.blog{
		width:280px;
		margin:0 auto rhythm(2);
		@include font-smoothing;
		text-align: left;

		@include breakpoint($medium){
			display: inline-block;
			vertical-align:top;
			margin-bottom:15px;
			float:right;
			width:31%;
			margin-right:0;
		}

		@include breakpoint($large){
			float:right;
			width:27%;
			
		}

		&:nth-of-type(1){
			@include breakpoint($medium){
				float:left;
				width:65%;
			}

			@include breakpoint($large){
				width:68%;
			}

			.title{
				@include breakpoint($medium){
					@include adjust-font-size-to(36px);
				}

				@include breakpoint($large){
					@include adjust-font-size-to(48px);
				}
			}
		}

		&:nth-of-type(3){
			@include breakpoint($medium){
				margin-right:0;
			}
		}

		> a{
			display: block;

			&:hover{
				img{
					opacity:0.7;
				}
			}
		}

		img{
			margin:0;
		}

		.title{
			@include adjust-font-size-to(24px);
			line-height:32px;
			margin-top:10px;
			margin-bottom:0;

			a{
				color:$gray;
				display: block;

				&:hover{
					color:$purple;
				}
			}
		}

		.date{
			color:$purple;
			margin-bottom:0;
		}
	}
}