/* ==========================================================================
	Footer
========================================================================== */

.siteFooter {
	background:#000;
}

.footerTop{
	background: #000 url('../img/footertaps.jpg') no-repeat center;
	background-size:cover;
	text-align: center;
	color:$cream;
	text-align:center;
	height:100%;
	width:100%;

	@include breakpoint($large){
		background-position:left;
		padding: 3% 0;
		background-attachment:fixed;
	}

	.wrapper{
		padding: 20px;
		margin: 0 auto;
		@include clearfix;
		position: relative;

		@include breakpoint($medium){
			max-width:730px;
			padding:20px 0;
		}

		@include breakpoint($large){
			max-width:940px;
		}
	}

	.h3{
		text-transform:uppercase;
		border-bottom:2px dotted $cream;
		padding-bottom:10px;
		margin-bottom:10px;
		@include adjust-font-size-to(24px);
		font-weight:500;
	}

	p{
		margin-bottom:0;
		@include adjust-font-size-to(18px);
	}
}

.logobox{
	position: relative;
	margin:0 auto;
	width:210px;

	@include breakpoint($medium){
		position: absolute;
		left:29%;
		top: 20%;
		width:300px;
	}

	@include breakpoint($large){
		left:32%;
		width:335px;
	}

	.footer-logo{
		display: block;
		text-indent:-9999px;
		margin:0 auto rhythm(1);
		display: block;
		height:61px;
		color: #fff;
		background:url('../img/logo-footer.png') no-repeat center;
		background-image:url('../img/logo-footer.svg'), none;
		background-size:185px;
		transition:(opacity 0.2s ease);

		@include breakpoint($medium){
			background-size:cover;
			height:100px;
			margin-bottom:10px;
		}

		@include breakpoint($large){
			height:115px;
		}

		&:hover{
			opacity:0.7;
		}
		
	}
}

.social{
	margin:0 0 rhythm(1);
	padding:0;
	list-style:none;

	li{
		display: inline-block;
		margin:0 22px 0 0;

		&:last-child{
			margin:0;
		}

		a{
			display: block;
			text-indent:-9999px;
			height:32px;
			width:32px;
			opacity:0.3;
			color: #fff;
			transition:(opacity 0.2s ease);

			&:hover{
				opacity:1;
			}
		}
	}

	.twitter{
		a{
			background:url('../img/icon-x-twitter.svg') no-repeat center;
			background-image: url('../img/icon-x-twitter.svg'), none;
		}
	}
	.facebook{
		a{
			background:url('../img/icon-facebook.png') no-repeat center;
			background-image: url('../img/icon-facebook.svg'), none;
		}
	}
	.instagram{
		a{
			background:url('../img/icon-instagram.png') no-repeat center;
			background-image: url('../img/icon-instagram.svg'), none;
		}
	}
	.youtube{
		a{
			background:url('../img/icon-youtube.png') no-repeat center;
			background-image: url('../img/icon-youtube.svg'), none;
		}
	}
}

.footerblock{
	width:258px;
	margin: 0 auto rhythm(1);

	@include breakpoint($medium){
		display: inline-block;
		vertical-align:top;
		width:25%;
	}

	@include breakpoint($large){
		width:28%;
	}
}

.footerlocation{

	@include breakpoint($medium){
		float:left;
	}

	a{
		color:$yellow;
		margin-top:15px;
		display: block;

		&:hover{
			color:lighten($yellow, 15);
		}
	}
}

.newsletterSignup {
	@include breakpoint($medium) {
		float:right;
	}
	.iframe-full-height {
		height: 100%;
    min-height: 231px;
	}
	.newsletter-title {
		text-transform:uppercase;
		border-bottom:2px dotted $cream;
		padding-bottom:10px;
		margin-bottom:10px;
		@include adjust-font-size-to(24px);
		font-weight:500;
		color: $cream;
		text-align: center;
	}

	label{
		margin-bottom:8px;
		display: block;
		@include adjust-font-size-to(18px);
		font-weight:500;
		color: $cream;
    text-align: center;
	}

	.newsletterInput{
		background:rgba($cream, 0.2);
	}
	.clear {
		text-align: center;
	}
	.newsletterSubmit{
		background:#767676;
		padding:10px 35px;
		display: inline-block;
		margin:8px auto;
		color:#000;
		text-transform:uppercase;
		font-family:$sans-serif;
		border:0;
		border-radius:0;
		font-weight:500;
		transition:(all 0.2s ease);

		&:hover{
			color:#fff;
			background:$yellow;
		}
	}
}

.bottomFooter{
	background:#000;
	color:$cream;
	text-align:center;

	@include breakpoint($medium){
		text-align:left;
	}

	.wrapper{
		padding:20px;
		@include clearfix;

		@include breakpoint($medium){
			padding:20px 0;
			margin:0 auto;
			max-width:720px;
		}

		@include breakpoint($large){
			max-width:980px;
			padding:10px 0;
		}
	}

	.footerNav{
		position: relative;

		@include breakpoint($medium){
			display: inline-block;
			vertical-align:top;
			margin-left:15px;
		}

		ul{
			margin:0 auto;
			padding:0;
			list-style: none;
			@include adjust-font-size-to(14px);

			li{
				display: inline-block;
				margin-right:15px;
			}

			a{
				color:$cream;

				&:hover{
					color:$yellow;
				}
			}
		}
	}

	p{
		margin:0 auto;
		@include adjust-font-size-to(14px);
		display: block;

		@include breakpoint($medium){
			vertical-align:top;
			display: inline-block;
		}

		&.copyright{
			@include breakpoint($medium){
				float:left;
			}
		}
		&.credits{
			@include breakpoint($medium){
				float:right;
			}
		}
	}


}

.cyberwoven{
	text-decoration:none;
	color:$cream;

	span{
		color:$yellow;
	}

	&:hover{
		color:$cream;

		span{
			color:lighten($yellow, 15);
		}
	}
}