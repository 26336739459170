/* ==========================================================================
	Buttons
========================================================================== */

.button {
	display:inline-block;
	@include adjust-font-size-to(18px);
	font-weight:500;
	color:#000;
	background:$yellow;
	text-decoration:none;
	text-transform:uppercase;
	font-family:$sans-serif;
	padding:13px 19px;
	margin:rhythm(0.25,16px) auto rhythm(1.25,16px);
	position:relative;
	transition:all 0.2s ease;


	&:hover {
		background:lighten($yellow,15);
		color:#000;
	}

  &.white {
    background: #fff;
    color: $darkgray;
  }
}