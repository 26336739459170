/* ==========================================================================
	Breadcrumbs
========================================================================== */

.breadcrumb {
	padding:10px 0;
	@include adjust-font-size-to(12px);

	ul {
		margin:0 0 rhythm(1);
		padding:0;
		list-style:none;
	}
	li {
		display:inline;
	}
	a {
		display:inline-block;
		margin-right:15px;
	}
}