/* ==========================================================================
	Form Elements
========================================================================== */

.form-item{

	margin: 0 auto rhythm(1, 20px);

	label{
		display: block;
		@include font-size(20px);
		margin: 0 auto rhythm(0.5, 20px);
	}

	.form-required{
		color:$purple;
	}
}

.form-text, input[type="text" ], input[type="email" ], input[type="number" ]{
	padding: 10px 11px 10px;
	border: 1px solid #000;
	background: $cream;
	display: block;
	margin-bottom: 0;
	width: 100%;
	border-radius: 0px;
	@include placeholder{
		color:#000;
		opacity:1;
	}
	-webkit-appearance: none;

	&:focus{
		outline:1px solid $purple;
		border:1px solid #000;
	}
}

.selectStyle{
	background: $cream;
	padding: 0;
	margin-bottom: rhythm(1);
	position: relative;
	width: 100%;
	border: 1px solid #000;
	overflow:hidden;
	border-radius: 0;

	&:after {
		content:"\f0d7";
		font-family:"FontAwesome";
		position:absolute;
		top:1px; right:0;
		padding: 10px;
		font-size: 16px;
		line-height: 20px;
		color:#000000;
	}

	select{
		width: 105%;
		border: 0;
		padding: 8px 5px 8px 10px;
		background-color:transparent;
		background-image: none;
		-webkit-appearance: none;
	    -moz-appearance: none;
	    appearance: none;
	    position:relative;
	    z-index:1;
	    outline: none;
	    cursor: pointer;
	    color:#000000;
	    font-weight:500;
	//    -moz-appearance: window;
	}

	select:focus {
	    outline: none;
	}
}

select::-ms-expand{
	display: none;
}

:-moz-any(.selectStyle):after {
	pointer-events: none;
	z-index: 1;
}

.webform-datepicker, .webform-component-time{
	.selectStyle{
		width: 25%;
		display: inline-block;
		margin-bottom:0;
		vertical-align: middle;
	}

	.form-radios{
		display: inline-block;
		margin-left: 10px;

		.form-type-radio{
			display: inline-block;
		}
	}
}

.webform-component--multi-select{
	.selectStyle{
		&:after{
			content: '';
		}
	}
}

.form-checkboxes, .form-radios {
	margin-bottom:rhythm(1);
}
.form-type-checkbox, .form-type-radio {
	margin:rhythm(0.5) auto;

	label {
		display:inline;
		font-weight:500;
		text-transform:none;
		padding-left:3px;
		@include font-size(16px);
		margin: 0 auto rhythm(0.5, 16px);
	}
}


.form-textarea {
	border: 1px solid #000000;
	padding: 10px;
	width:100%;
	background: $cream;
	resize:none;
	border-radius: 0;
	@include placeholder{
		color:#000000;
		opacity:1;
	}
	-webkit-appearance: none;

	&:focus{
		outline:1px solid $purple;
		border:1px solid #000;
	}
}

.webform-component-textarea{
	.grippie{
		display: none !important;
	}
}

.form-actions {
	margin-bottom:rhythm(1);

	input[type="submit" ] {
		display:inline-block;
		@include font-size(18px);
		padding:10px 35px;
		margin:0 auto rhythm(0.75,18px);
		font-weight:600;
		color:#ffffff;
		background:$yellow;
		font-family:$sans-serif;
		border:0;
		text-decoration:none;
		position:relative;
		transition:background 0.2s ease;
		border-radius: 0;
		@include font-smoothing;
		text-transform: uppercase;

		&:hover {
			background: lighten($yellow, 15);
		}

		&:active{
		}

		&:focus{
			outline:none;
		}
	}
}

.webform-datepicker{
	position: relative;

	.selectStyle{
		width:100%;
		margin: 0 0 16px;

		@include breakpoint($small){
			width: 30%;
		}
	}
}

fieldset{

	legend{
		display: block;
		margin-bottom: 8px;
	}

	.fieldset-legend{
		@include font-size(30px);
		font-weight: 300;
		color:$purple;
	}
}

li:not(#foo) > input[type='checkbox'], div:not(#foo) > input[type='checkbox']{
	opacity:0;
	float:left;
	width: 18px;
	left: 14px;
	position: relative;
	cursor:pointer;
}

li:not(#foo) > input[type='checkbox'] + label, div:not(#foo) > input[type='checkbox'] + label{
	margin:0;
	clear:none;
	padding: 0px 0 0px 24px;
	cursor:pointer;
	display: block;
	background:url('../img/checkbox-unchecked.png') no-repeat left 2px;
	background-image: url('../img/checkbox-unchecked.svg'), none;
	background-size:18px;
}

li:not(#foo) > input[type='checkbox']:checked + label, div:not(#foo) > input[type='checkbox']:checked + label{
	background: url('../img/checkbox-checked.png') no-repeat left 2px;
	background-image: url('../img/checkbox-checked.svg'), none;
	background-size:18px;
}

li:not(#foo) > input[type='radio'], div:not(#foo) > input[type='radio']{
	opacity:0;
	float:left;
	width: 18px;
	left: 14px;
	position: relative;
	cursor:pointer;
}

li:not(#foo) > input[type='radio'] + label, div:not(#foo) > input[type='radio'] + label{
	margin:0;
	clear:none;
	padding: 0px 0 0px 24px;
	cursor:pointer;
	display: block;
	background:url('../img/radio-unchecked.png') no-repeat left 2px;
	background-image: url('../img/radio-unchecked.svg'), none;
}

li:not(#foo) > input[type='radio']:checked + label, div:not(#foo) > input[type='radio']:checked + label{
	background: url('../img/radio-checked.png') no-repeat left 2px;
	background-image: url('../img/radio-checked.svg'), none;
}

footer.siteFooter input[type="email" ] {
	border: 1px solid $lightgray;	
}